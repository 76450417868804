import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import { Link, TextInput } from 'hds-react';
import ExternalLink from '../../../components/ExternalLink';
import Playground from '../../../components/Playground';
import TabsLayout from './tabs.mdx';
export const _frontmatter = {
  "slug": "/components/text-input/code",
  "title": "TextInput - Code"
};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const IconCheckCircleFill = makeShortcode("IconCheckCircleFill");
const layoutProps = {
  _frontmatter
};
const MDXLayout = ({
  children,
  pageContext
}) => <TabsLayout pageContext={pageContext}>{children}</TabsLayout>;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h2 {...{
      "id": "code",
      "style": {
        "position": "relative"
      }
    }}>{`Code`}<a parentName="h2" {...{
        "href": "#code",
        "aria-label": "code permalink",
        "className": "header-anchor after"
      }}><span parentName="a" {...{
          "className": "hds-anchor-icon hds-icon hds-icon--link hds-icon--size-xs",
          "aria-hidden": "true",
          "style": {
            "verticalAlign": "middle"
          }
        }}></span></a></h2>
    <h3 {...{
      "id": "code-examples",
      "style": {
        "position": "relative"
      }
    }}>{`Code examples`}<a parentName="h3" {...{
        "href": "#code-examples",
        "aria-label": "code examples permalink",
        "className": "header-anchor after"
      }}><span parentName="a" {...{
          "className": "hds-anchor-icon hds-icon hds-icon--link hds-icon--size-xs",
          "aria-hidden": "true",
          "style": {
            "verticalAlign": "middle"
          }
        }}></span></a></h3>
    <h4 {...{
      "id": "default",
      "style": {
        "position": "relative"
      }
    }}>{`Default`}<a parentName="h4" {...{
        "href": "#default",
        "aria-label": "default permalink",
        "className": "header-anchor after"
      }}><span parentName="a" {...{
          "className": "hds-anchor-icon hds-icon hds-icon--link hds-icon--size-xs",
          "aria-hidden": "true",
          "style": {
            "verticalAlign": "middle"
          }
        }}></span></a></h4>
    <Playground mdxType="Playground">
      <pre><code parentName="pre" {...{
          "className": "language-jsx"
        }}>{`<div style={{ maxWidth: '320px' }}>
<TextInput
  id="textinput"
  label="Label"
  placeholder="Placeholder"
  helperText="Assistive text"
  required
/>
<TextInput
  id="textinput-invalid"
  label="Label"
  defaultValue="Text input value"
  helperText="Assistive text"
  style={{marginTop: 'var(--spacing-s)'}}
  disabled
/>
<TextInput
  id="textinput-invalid"
  label="Label"
  defaultValue="Text input value"
  errorText="Error text"
  helperText="Assistive text"
  style={{marginTop: 'var(--spacing-s)'}}
  invalid
/>
</div>
`}</code></pre>
      <pre><code parentName="pre" {...{
          "className": "language-html"
        }}>{`<div style="max-width:320px;">
  <div class="hds-text-input">
    <label for="textinput-core" class="hds-text-input__label">
      Label
      <span class="hds-text-input__required">*</span>
    </label>
    <div class="hds-text-input__input-wrapper">
      <input id="textinput-core" class="hds-text-input__input" type="text" placeholder="Placeholder" required />
    </div>
    <span class="hds-text-input__helper-text">Assistive text</span>
  </div>

  <div class="hds-text-input" style="margin-top:var(--spacing-s);">
    <label for="textinput-disabled-core" class="hds-text-input__label">
      Label
    </label>
    <div class="hds-text-input__input-wrapper">
      <input id="textinput-disabled-core" class="hds-text-input__input" type="text" value="Text input value" disabled />
    </div>
    <span class="hds-text-input__helper-text">Assistive text</span>
  </div>

  <div class="hds-text-input hds-text-input--invalid" style="margin-top:var(--spacing-s);">
    <label for="textinput-invalid-core" class="hds-text-input__label">
      Label
    </label>
    <div class="hds-text-input__input-wrapper">
      <input id="textinput-invalid-core" class="hds-text-input__input" type="text" value="Text input value" />
    </div>
    <span class="hds-text-input__error-text">Error text</span>
    <span class="hds-text-input__helper-text">Assistive text</span>
  </div>
</div>
`}</code></pre>
    </Playground>
    <h4 {...{
      "id": "read-only",
      "style": {
        "position": "relative"
      }
    }}>{`Read-only`}<a parentName="h4" {...{
        "href": "#read-only",
        "aria-label": "read only permalink",
        "className": "header-anchor after"
      }}><span parentName="a" {...{
          "className": "hds-anchor-icon hds-icon hds-icon--link hds-icon--size-xs",
          "aria-hidden": "true",
          "style": {
            "verticalAlign": "middle"
          }
        }}></span></a></h4>
    <Playground mdxType="Playground">
      <pre><code parentName="pre" {...{
          "className": "language-jsx"
        }}>{`<TextInput
  id="textinput-readonly"
  label="Label"
  defaultValue="Read-only value"
  helperText="Assistive text"
  readOnly
/>
`}</code></pre>
      <pre><code parentName="pre" {...{
          "className": "language-html"
        }}>{`<div class="hds-text-input">
  <label for="textinput-readonly-core" class="hds-text-input__label">
    Label
  </label>
  <div class="hds-text-input__input-wrapper">
    <input id="textinput-readonly-core" class="hds-text-input__input" type="text" value="Text input value" readonly />
  </div>
  <span class="hds-text-input__helper-text">Assistive text</span>
</div>
`}</code></pre>
    </Playground>
    <h4 {...{
      "id": "with-tooltip",
      "style": {
        "position": "relative"
      }
    }}>{`With tooltip`}<a parentName="h4" {...{
        "href": "#with-tooltip",
        "aria-label": "with tooltip permalink",
        "className": "header-anchor after"
      }}><span parentName="a" {...{
          "className": "hds-anchor-icon hds-icon hds-icon--link hds-icon--size-xs",
          "aria-hidden": "true",
          "style": {
            "verticalAlign": "middle"
          }
        }}></span></a></h4>
    <Playground mdxType="Playground">
      <pre><code parentName="pre" {...{
          "className": "language-jsx"
        }}>{`<TextInput
  id="textinput-tooltip"
  label="Label"
  placeholder="Placeholder"
  tooltipLabel="Tooltip label"
  tooltipButtonLabel="Tooltip button label"
  tooltipText="Tooltips contain &quot;nice to have&quot; information. Default Tooltip contents should not be longer than two to three sentences. For longer descriptions, provide a link to a separate page."
/>
`}</code></pre>
    </Playground>
    <h3 {...{
      "id": "packages",
      "style": {
        "position": "relative"
      }
    }}>{`Packages`}<a parentName="h3" {...{
        "href": "#packages",
        "aria-label": "packages permalink",
        "className": "header-anchor after"
      }}><span parentName="a" {...{
          "className": "hds-anchor-icon hds-icon hds-icon--link hds-icon--size-xs",
          "aria-hidden": "true",
          "style": {
            "verticalAlign": "middle"
          }
        }}></span></a></h3>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Package`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Included`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Storybook link`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Source link`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`HDS React`}</strong></td>
          <td parentName="tr" {...{
            "align": null
          }}><div style={{
              display: 'flex',
              gap: 'var(--spacing-3-xs)'
            }}><IconCheckCircleFill mdxType="IconCheckCircleFill" />{` Yes `}</div></td>
          <td parentName="tr" {...{
            "align": null
          }}><Link openInNewTab openInNewTabAriaLabel="Opens in a new tab" href="/storybook/react/?path=/story/components-textinput--default" mdxType="Link">{`View in Storybook`}</Link></td>
          <td parentName="tr" {...{
            "align": null
          }}><ExternalLink openInNewTab openInNewTabAriaLabel="Opens in a new tab" href="https://github.com/City-of-Helsinki/helsinki-design-system/tree/master/packages/react/src/components/textInput" mdxType="ExternalLink">{`View source`}</ExternalLink></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`HDS Core`}</strong></td>
          <td parentName="tr" {...{
            "align": null
          }}><div style={{
              display: 'flex',
              gap: 'var(--spacing-3-xs)'
            }}><IconCheckCircleFill mdxType="IconCheckCircleFill" />{` Yes `}</div></td>
          <td parentName="tr" {...{
            "align": null
          }}><Link openInNewTab openInNewTabAriaLabel="Opens in a new tab" href="/storybook/core/?path=/story/components-text-input--default" mdxType="Link">{`View in Storybook`}</Link></td>
          <td parentName="tr" {...{
            "align": null
          }}><ExternalLink openInNewTab openInNewTabAriaLabel="Opens in a new tab" href="https://github.com/City-of-Helsinki/helsinki-design-system/tree/master/packages/core/src/components/text-input" mdxType="ExternalLink">{`View source`}</ExternalLink></td>
        </tr>
      </tbody>
    </table>
    <h3 {...{
      "id": "properties",
      "style": {
        "position": "relative"
      }
    }}>{`Properties`}<a parentName="h3" {...{
        "href": "#properties",
        "aria-label": "properties permalink",
        "className": "header-anchor after"
      }}><span parentName="a" {...{
          "className": "hds-anchor-icon hds-icon hds-icon--link hds-icon--size-xs",
          "aria-hidden": "true",
          "style": {
            "verticalAlign": "middle"
          }
        }}></span></a></h3>
    <p>{`Note! You can find the full list of properties in the `}<Link openInNewTab openInNewTabAriaLabel="Opens in a new tab" href="/storybook/react/?path=/docs/components-textinput--default" mdxType="Link">{`React Storybook.`}</Link></p>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Property`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Description`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Values`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Default value`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`label`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The label for the input.`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`ReactNode`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`-`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`errorText`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The error text content that will be shown below the input.`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`string`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`-`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`helperText`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The error text content that will be shown below the input.`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`string`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`-`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`hideLabel`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Hides the label above the input.`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`boolean`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`false`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`invalid`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`If set to true, the input will be displayed in an invalid state.`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`boolean`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`false`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`successText`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The success text content that will be shown below the input.`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`string`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`-`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`infoText`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The info text content that will be shown below the input.`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`string`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`-`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`tooltipLabel`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The aria-label text for the tooltip.`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`string`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`-`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`tooltipButtonLabel`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The aria-label text for the tooltip trigger button.`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`string`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`-`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`tooltipText`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The text content of the tooltip.`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`string`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`-`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`buttonIcon`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Button icon.`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`ReactNode`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`-`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`buttonAriaLabel`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The aria-label for the button.`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`string`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`-`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`onButtonClick`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Callback called when the button is pressed.`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`function`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`-`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`[Table 1:TextInput properties]`}</td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
        </tr>
      </tbody>
    </table>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      